import React from "react";
import { graphql } from 'gatsby'
import MainHeader from "../components/main-header";
import Video from "../components/feature-video";
import VimeoLink from "../components/vimeo-link";
import { renderTestimonialSection } from "../components/home/testimonialSection";
import { resetWebflow } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Schema from "../components/common/schema";
import Notification from "../components/header/notification";
import OurSolutions from "../components/home/ourSolutions";
import DashboardToStoryTelling from "../components/home/dashboard-to-storytelling";
import StorytellingPlatform from "../components/home/storytelling-platform";
import CombineYourData from "../components/home/combine-data";
import DataTellingCompany from "../components/home/data-telling-company";
import CommonQuestions from "../components/common-question";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsGlobalConfiguration: globalVideo,
    datoCmsHome: {
      seoMetaTags,
      firstSection,
      testimonialSection,
      ourSolutions,
      dashboardToStorytelling,
      combineYourData,
      dataStorytellingCompany,
      commonQuestions,      
      schema,
      notificationPage      
    },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {  
  resetWebflow("5aabd62c51e22ea0dd346d0c");

  const video = firstSection.find(
    block => block.model && block.model.apiKey === "feature_video"
  );
  const firstSectionTmpl = firstSection.map(block => {
    return (
      <React.Fragment key={block.id}>
        {block.model && block.model.apiKey === "story_telling" && (
          <MainHeader data={block} props={{ heroClass: "h-fixedwidth", requestDemoClass: "tracking-demo-header-home", buttonClass: "tracking-video-home" }}>
            <Video data={video} divClass="video-home w-embed" />
          </MainHeader>
        )}
      </React.Fragment>
    );
  });
  const testimonialSectionTmpl = renderTestimonialSection(testimonialSection);

  return (
    <Layout>
      <Schema data={schema} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <HelmetDatoCms seo={seoMetaTags} />
      <div className="tint-container">
        <div className="flex-container center">
          <div id="closevid" className="close-popup" />
          <VimeoLink data={globalVideo} />
        </div>
      </div>
      {firstSectionTmpl}
      {testimonialSectionTmpl}

      <main class="white-section reducedbottom">
        <OurSolutions data={ourSolutions} />
        <DashboardToStoryTelling data={dashboardToStorytelling} />
      </main>

      <div class="white-section reducedbottom">
        <div class="container center">
          <StorytellingPlatform data={dashboardToStorytelling} />
          <CombineYourData data={combineYourData} />
        </div>
      </div>
      <DataTellingCompany data={dataStorytellingCompany} />
      <section class="white-section gray">
        <div class="container">
          <CommonQuestions data={commonQuestions} />
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery {
    datoCmsGlobalConfiguration {
      video: whatIsNugitVideo {
        providerUid
        url
      }
    }
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schema {
        ... on DatoCmsSchemaDetail {
          id
          jsonSchema
        }
      }
      firstSection {
        ... on DatoCmsStoryTelling {
          id
          title
          subTitle
          model {
            apiKey
          }
        }        
        ... on DatoCmsFeatureVideo {
          video {
            url
          }
          fallbackImage {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      testimonialSection {
        ... on DatoCmsTestimonialDetail {
          id
          header
          text
          user
          userRole
          userAvatar {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      ourSolutions {
        ... on DatoCmsSummary {
          title
          subTitle
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureDetail {
          header
          title
          icon {
            alt
            url
          }
          cta
          ctaCopy
          model {
            apiKey
          }
        }
      }
      dashboardToStorytelling {
        ... on DatoCmsSummaryWithImage {
          title
          subtitle
          image {
            alt
            url
          }
          cta
          ctaCopy
          model {
            apiKey
          }
        }
        ... on DatoCmsTestimonialDetail {
          id
          header
          text
          user
          userRole
          userAvatar {
            alt
            url
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBeforeaftercompare {
          before {
            url
            alt
          }
          after {
            url
            alt
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeatureDetail {
          header
          title
          icon {
            alt
            url
          }
          model {
            apiKey
          }
        }
      }
      combineYourData {
        ... on DatoCmsSummary {
          title
          subTitle
          model {
            apiKey
          }
        }        
        ... on DatoCmsSlide {
          id
          name
          details
          imageUrl {
            url
          }
          integrationColor {
            hex
          }
          model {
            apiKey
          }
          linkTo {
            slug
          }
        }
        ... on DatoCmsCallToAction {
          cta
          ctaCopy
          model {
            apiKey
          }
        }
      }
      dataStorytellingCompany {
        ... on DatoCmsCaseStudyBlock {
          title
          caseStudyType
          icon {
            alt
            url
          }
          featurePhoto {
            url
            alt
          }
          linkTo {
            slug
          }
          model {
            apiKey
          }
        }
      }
      commonQuestions {
        ... on DatoCmsQuestion {
          question
          answer
          model {
            apiKey
          }
        }
      }      
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
