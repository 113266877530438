import React from "react";
import Testimonial from "../../components/common/testimonial";
import { Link } from "gatsby";
import BeforeAfter from "../common/before-after";
import { isModelOfType } from "../../utils/common";

export default ({ data }) => {
  let summary = data.find(item => isModelOfType(item, "summary_with_image"));
  let testimonial = data.find(item =>
    isModelOfType(item, "testimonial_detail")
  );
  let beforeAfter = data.find(item =>
    isModelOfType(item, "beforeaftercompare")
  );
  return (
    <div class="container center">
      <div class="hg-spacing" />
      {summary && (
        <React.Fragment>
          {summary.image && (
            <div
              class="no-dashboard-badge"
              style={{ backgroundImage: `url(${summary.image.url})` }}
            />
          )}
          <div class="sm-spacing" />
          <h2 class="h2">{summary.title}</h2>
          <div class="sm-spacing" />
        </React.Fragment>
      )}
      <Testimonial data={testimonial} />
      <div class="sm-spacing" />
      {
        (summary.cta || summary.ctaCopy) && <Link to={summary.cta} className="link-text w-inline-block w-clearfix">
          <div class="learn-more">{summary.ctaCopy}</div>
          <div class="arrow-right" />
        </Link>
      }
      <div class="lg-spacing" />
      <BeforeAfter data={beforeAfter} />

    </div>
  );
};
