import React from 'react'
import { Link } from "gatsby";

export default ({ data }) => {
    let linkTo = data.linkTo && data.linkTo.slug ? `/integration/${data.linkTo.slug}` : "";
    return (
        <React.Fragment>
            <div className="slide-content w-slide">
                <div className="center-wrapper">
                    <Link to={linkTo} className="shadow-box integration w-inline-block">
                        <div className="integration-color" style={{
                            backgroundColor: `${data.integrationColor.hex}`,
                            backgroundImage: `url(${data.imageUrl && data.imageUrl.url})`,
                            backgroundSize: "auto",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "50% 50%"
                        }} />
                        <div className="platform-detail integration">
                            <h6 className="h6">{data.name}</h6>
                            <div className="tiny-copy right">{data.details} </div>
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    );
};