import React from "react";
import { Link } from "gatsby";
import allCaseStudiesImg from "../../styles/images/see-all-story.jpeg";

import { isModelOfType, resizeAndConvertImage } from "../../utils/common";

export default ({ data }) => {
  let caseStudies = data.filter(item =>
    isModelOfType(item, "case_study_block")
  );
  caseStudies.slice(0, 4);
  let lastCaseStudy = caseStudies[caseStudies.length - 1];
  let seeAllImg =
    caseStudies === 4 ? lastCaseStudy.icon.url : allCaseStudiesImg;   
  return (
    <React.Fragment>
      {caseStudies.length && (
        <div class="white-section reducedbottom">
          <div class="container">
            <div class="content-center">
              <h2 class="h2">Join these Data Storytelling Companies today</h2>
              <div class="md-spacing" />
            </div>
          </div>
          <div class="container-fluid">
            <div class="flex-container">
              {caseStudies.map(item => (
                <Link
                  to={`/case-study/${item.linkTo && item.linkTo.slug}`}
                  class="case-study-home tracking-cs w-inline-block"
                >
                  {item.caseStudyType === "Video" && <div class="play-icon" />}
                  {item.featurePhoto && (
                    <img
                      src={`${item.featurePhoto.url}${resizeAndConvertImage(800)}`}
                      alt={item.featurePhoto.alt}
                      class="case-study-home-bg"
                    />
                  )}
                  <div class="dark-gradient">
                    <div class="flex-container right">
                      <div class="author-avatar-big no-border">
                        {item.icon && (
                          <img
                            src={`${item.icon.url}${resizeAndConvertImage(200)}`}
                            alt={item.icon.alt}
                            class="rounded-img"
                          />
                        )}
                      </div>
                      <div class="author-details-container">
                        <h6 class="h6">{item.title}</h6>
                        <div class="small-copy">
                          {item.caseStudyType === "Video"
                            ? "Watch Video"
                            : "Read"}{" "}
                          Case Study
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
              <Link to="/case-study" class="case-study-home tracking-cs w-inline-block">
                <div class="tint" />
                <img src={seeAllImg} alt="See All" class="case-study-home-bg" />
                <div class="dark-gradient">
                  <div class="flex-container right">
                    <div class="author-details-container">
                      <h6 class="h6">More Case Studies</h6>
                      <div class="small-copy">See All</div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
