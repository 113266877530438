import React from "react";
import { Link } from "gatsby";
function getClassName(fn) {
  try {
    return fn() || "";
  } catch (e) {
    return "";
  }
}
export default ({ data, props, children }) => {
  return (
    <header className={`main-header ${getClassName(() => props.headerClass)}`}>
      <div className={`container ${getClassName(() => props.firstDivClass)}`}>
        <div className={`${getClassName(() => props.divClass)}`}>
          <div className={`${getClassName(() => props.containerClass)}`}>
            <h1 className={`${getClassName(() => props.heroClass)}`}>
              {data.title}
            </h1>
            <div className="sm-spacing" />
            <div>{data.subTitle}</div>
            <div className="sm-spacing _30" />
            <Link to="/request-demo" className={`btn w-button ${getClassName(() => props.requestDemoClass)}`}>
              Request Demo
            </Link>
            {!props.hideWhatIsNugitBtn && <Link
              to="/product"
              className={`btn outline w-button ${getClassName(
                () => props.buttonClass
              )}`}
            >
              What Is Nugit
            </Link>}
          </div>
        </div>
        {children}
      </div>
    </header>
  );
};
