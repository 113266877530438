import React from "react";
import ReactMarkdown from "react-markdown";

export default ({ data }) => {
  return (
    <React.Fragment>
      <h2 className="h2">Common Questions</h2>
      <div className="sm-spacing" />
      <p className="p center-align">You can find the answers here</p>
      <div className="md-spacing" />
      <div className="divider" />
      {data.map(item => (
        <React.Fragment key={item.id}>
          <div className="faq faq-container w-dropdown">
            <div className="faq-toggle w-dropdown-toggle">
              <h5 style={{ pointerEvents: "none" }}>{item.question}</h5>
              <div className="arrow-down qa" />
            </div>
            <div className="faq-answer faq-a-bg w-dropdown-list">
              <a className="faq-a w-dropdown-link">
                <ReactMarkdown source={item.answer} escapeHtml={false} />
              </a>
            </div>
          </div>
          <div className="divider" />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
