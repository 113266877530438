import React from "react";
//// DEVELOPMENT
// import $ from 'jquery';

export default ({ data }) => {
  if (!data) {
    return "";
  }

  let { before, after } = data;
  let beforeRef = before && <img src={before.url} alt={before.alt} />;
  let afterRef = after &&<img src={after.url} alt={after.alt} />

  try {
    setTimeout(() => {
      try {
        $("#after-before-container").twentytwenty(); 
      } catch (error) {
        
      }    
    }, 300);  
  } catch (error) {
    
  }
  
  return (
    <React.Fragment>
      <div id="after-before-container" class="twentytwenty-container">
        {beforeRef}
        {afterRef}
      </div>      
    </React.Fragment>
  );
};
