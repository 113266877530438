import React from "react";
import { isModelOfType, getSafe } from "../../utils/common";
import { Link } from "gatsby";

export default ({ data }) => {
    let summary = getSafe(
        () => data.filter(item => isModelOfType(item, "summary_with_image"))[1]
    );
    let features = data.filter(item => isModelOfType(item, "feature_detail"));
    return (
        <React.Fragment>
            {summary && (
                <React.Fragment>
                    <h2 class="h2">{summary.title}</h2>
                    <div class="sm-spacing" />
                    <p>{summary.subtitle}</p>
                    {summary.image && (
                        <div class="data-storytelling-platform-screen">
                            <img
                                src={summary.image.url}
                                alt={summary.image.alt}
                                class="img-shadow-lg"
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
            <div class="grid-container-2">
                {features.map(feature => (
                    <div class="flex-container start">
                        {feature.icon && (
                            <div
                                class="outline-icon"
                                style={{
                                    backgroundImage: `url(${feature.icon.url})`,
                                    backgroundPosition: "50% 50%",
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat"
                                }}
                            />
                        )}
                        <div class="content-w-outline-icon">
                            <div class="content-left">
                                <h6>{feature.header}</h6>
                                <div class="tn-spacing" />
                                <p>{feature.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div class="md-spacing" />
            {(summary.cta || summary.ctaCopy) && <Link to={summary.cta} className="link-text w-inline-block w-clearfix">
                <div class="learn-more">{summary.ctaCopy}</div>
                <div class="arrow-right" />
            </Link>}
        </React.Fragment>
    );
};
