import React from 'react'
import { Link } from "gatsby";

export default ({data}) => {    
    return (
        <React.Fragment>
            <div className="slide-content w-slide">
                <div className="center-wrapper">
                    <Link
                        to={data.cta || "/data-integrations"}
                        className="shadow-box integration showmore w-inline-block"
                    >
                        <div className="link-text w-clearfix">
                            <div className="learn-more">{data.ctaCopy || "Learn More"}</div>
                            <div className="arrow-right" />
                        </div>
                    </Link>
                </div>
            </div>
        </React.Fragment>
    )
}