import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import { isModelOfType } from "../../utils/common";

export default ({ data }) => {
  let summary = data.find(item => isModelOfType(item, "summary"));
  let solutions = data.filter(item => isModelOfType(item, "feature_detail"));

  return (
    <div class="container grid">
      {summary && (
        <div class="content-center">
          <h2 class="h2">Discover our solutions</h2>
        </div>
      )}
      <div class="md-spacing" />
      {solutions.map(solution => (
        <div class="_3cols-box left-align center-m">
          {solution.icon && (
            <div class="illustration left-align solution">
              <img
                src={solution.icon.url}
                alt={solution.icon.alt}
                width="140"
              />
            </div>
          )}
          <div class="sm-spacing" />
          <h4 class="h4">{solution.header}</h4>
          <div class="sm-spacing" />
          <p class="p">
            <ReactMarkdown source={solution.title} escapeHtml={false} />
          </p>
          <div class="sm-spacing" />
          {(solution.cta || solution.ctaCopy) && (
            <Link
              className="link-text w-inline-block w-clearfix"
              to={solution.cta}
            >
              <div class="learn-more">{solution.ctaCopy}</div>
              <div class="arrow-right" />
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};
