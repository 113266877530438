import React from "react";
import { isModelOfType } from "../../utils/common";
import Slide from "../../components/product/productSlider";
import LearnMore from "../../components/product/learnMore";

export default ({ data }) => {
  let summary = data.find(item => isModelOfType(item, "summary"));
  let slides = data.filter(item => isModelOfType(item, "slide"));
  let callToAction = data.find(item => isModelOfType(item, "call_to_action"));

  return (
    <React.Fragment>
      <div class="hg-spacing" />
      <h2 class="h2">{summary.title}</h2>
      <div class="sm-spacing" />
      <p class="p center-align">{summary.subTitle}</p>
      <div class="md-spacing" />
      <div
        data-animation="slide"
        data-easing="ease-in"
        data-hide-arrows="1"
        data-duration="500"
        data-infinite="1"
        class="slider w-slider"
      >
        <div className="w-slider-mask">
          {slides.map(slide => (
            <Slide data={slide} />
          ))}
          <LearnMore data={callToAction} />
        </div>
        <div class="slider-arrow-l w-hidden-medium w-hidden-small w-hidden-tiny w-slider-arrow-left">
          <div class="w-icon-slider-left"></div>
        </div>
        <div class="slider-arrow-r w-hidden-medium w-hidden-small w-hidden-tiny w-slider-arrow-right">
          <div class="w-icon-slider-right"></div>
        </div>
        <div class="slide-nav w-hidden-main w-slider-nav w-slider-nav-invert w-round"></div>
      </div>
    </React.Fragment>
  );
};
